.picker-calendar {
  width: 300px;
  max-width: 300px;
  background: white;
  line-height: 1em;
}
.tile {
  height: 40px;
}
.tile abbr {
  min-width: auto;
  border-radius: 999px;
  padding : 8px 10px;
}
.tile:enabled:hover abbr, .tile:enabled:focus abbr{
  background-color: #eaeaea;
  color: black;
}
