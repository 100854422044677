.dot {
  background: #64c4ea;
  border-radius: 999px;
  font-size: 0.75em;
  margin: 2px auto 0;
  width: 24px;
  height: 1.5em;
  padding: 2px 0;
  line-height: 1.125em;
  color: black;
}
.white {
  background: transparent;
}
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  padding-bottom: 18px;
  border-bottom: 2px solid #d8d8d8;
}
.react-calendar__month-view__weekNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 5px 5px;
  background: none;
  text-align: center;
  height: 80px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: white;
}
/* .react-calendar__tile--now.react-calendar__tile:enabled:hover,
.react-calendar__tile--now.react-calendar__tile:enabled:focus {
  background-color: #f0f0f0;
  border: 2px dotted #64c4ea;
} */
abbr {
  text-decoration: none !important;
}
.react-calendar__tile abbr {
  display: inline-block;
  min-width: 40px;
  padding: 10px;
  border-radius: 999px;
}
.react-calendar__tile--now abbr {
  background-color: #5078df;
  color: white;
}
/* .react-calendar__tile--active {
  background: #ffffa9;
  color: white;
} */
/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f0f0f0;
} */
.react-calendar__tile--hasActive {
  border: 2px solid #64c4ea66;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  border: 2px solid #64c4ea88;
}
/* .react-calendar__tile--active {
  border: 2px dotted #64c4ea;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border: 2px dotted #64c4ea;
} */
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
